html {
  /* background-image: url('./background.jpg');
  background-size: cover; */
  padding-top: 55px;
  padding-bottom: 110px;
  background-color: #000;
}

#cnv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media (max-width: 767px) {
  html {
    padding-top: 15px;
    padding-bottom: 100px;
  }
}

body {
  /* overflow: visible !important; */
}

/* @media (max-width: 767px) {
  .App {
    padding-bottom: 85px;
  }
} */

.app {
  padding-bottom: 70px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

button {
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

hr {
  border: 1px solid #3E474F;
  /* width: 600px; */
  width: 100%;
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

._container {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
}


@media (max-width: 767px) {
  ._container {
    padding: 0 15px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img {
  max-width: 100%;
  height: auto;
}

img[alt="networkpic"] {
  width: 19px;
  height: 19px;
  object-fit: contain;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .header {
    margin-bottom: 70px;
  }
}



.header_boxes {
  position: relative;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* padding-top: 55px; */
  /* padding-right: 130px; */
}

.header_boxes-col {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.header_boxes div {
  /* margin: 5px; */
}


@media (max-width: 767px) {
  .header_boxes {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .header_boxes-col {
    gap: 5px;
  }
}

ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.letter-holder {
  padding: 16px;
}

.letter {
  float: left;
}

.load-6 .letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.l-1 {
  animation-delay: 0.48s;
}

.l-2 {
  animation-delay: 0.6s;
}

.l-3 {
  animation-delay: 0.72s;
}

.l-4 {
  animation-delay: 0.84s;
}

.l-5 {
  animation-delay: 0.96s;
}

.l-6 {
  animation-delay: 1.08s;
}

.l-7 {
  animation-delay: 1.2s;
}

.l-8 {
  animation-delay: 1.32s;
}

.l-9 {
  animation-delay: 1.44s;
}

.l-10 {
  animation-delay: 1.56s;
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.will-block.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.will-block__wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  padding-bottom: 60px;
  padding-left: 50px !important;
  padding-right: 50px;
  max-width: 700px;
  width: 100%;
  color: #C1C7CD;
  overflow-y: auto;
  /* position: absolute; */
  position: fixed;
  left: 50%;
  /* top: 170px; */
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 400;
  top: 50%;
  z-index: 10;
  background-color: #1B232A;
  box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.25);
  height: auto;
}

@media (max-height: 1080px) {
  .will-block__wrapper {
    height: calc(100% - 100px);
  }
}

@media (max-width: 767px) {
  .will-block__wrapper {
    /* height: 100%; */
    overflow-x: auto;
    padding: 20px;
    padding-top: 14px;
    padding-bottom: 30px;
    max-width: 340px;
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 14px !important;
    padding-bottom: 27px !important;
    font-size: 14px;
    /* top: 135px; */
    top: 50%;
    transform: translate(-50%, -50%);
    height: auto;
  }
}

.btn-new-will {
  background: #5ED5A8;
  width: 350px;
  height: 50px;
  margin: auto;
  /* margin-top: 70px; */
  /* padding: 15px 136px; */
  box-shadow: 3px -2px 10px rgba(94, 213, 168, 0.4);
  border-radius: 15px;
  border: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.0264em;
  color: #171D22;
}

@media (max-width: 767px) {
  .btn-new-will {
    width: 200px;
    height: 40px;
    margin-top: 0;
    font-size: 14px;
  }
}

.btn-default {
  border: 1px solid #5ED5A8;
  border-radius: 15px;
  color: #5ED5A8;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: transparent;
  min-width: 120px;
  justify-content: center;
  gap: 5px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.btn-primary {
  box-shadow: 4px 0px 10px 4px rgba(94, 213, 168, 0.25);
}

.block-three {
  max-width: 924px;
  /* font-size: 20px; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  gap: 20px;
  /* margin-bottom: 20px; */
  /* margin-bottom: 44px; */
  color: #4285F4;
  /* margin: 45px 0; */
  margin-top: 25px;
  padding-bottom: 44px;
}

@media (max-width: 767px) {
  .block-three {
    font-size: 14px;
    line-height: 17px;
    margin-top: 0;
    /* margin-bottom: 50px; */
    margin-bottom: 19px;
    padding-bottom: 0;
    max-width: 320px;
  }
}

.block-two {
  max-width: 823px;
  font-weight: 600;
  text-align: center;
  color: #C1C7CD;
  font-family: 'SF UI Text';
  font-style: normal;
  font-size: 20px;
  font-weight: 600px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .block-two {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 30px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
  }

  .block-two:nth-child(4) {
    max-width: 300px;
  }
}

.block-one {
  max-width: 715px;
  margin-top: 30px;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #5ED5A8;
}

@media (max-width: 767px) {
  .block-one {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 21px;
    max-width: 300px;
  }
}

.block-one__title {
  /* width: 109px; */
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  color: #5ED5A8;
}

@media (max-width: 767px) {
  .block-one__title {
    font-size: 18px;
  }
}

.btn_connect-off {
  width: 200px;
  border: 1px solid #4285F4;
  border-radius: 15px;
  background: none;
  color: #4285F4;
  font-size: 18px;
  padding: 15px 32px;
}

a {
  color: #5ED5A8
}

.block-four {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4285F4;
  margin-bottom: 44px;
}

.btn-connect {
  display: flex;
  width: 190px;
  border: 1px solid #5ED5A8;
  border-radius: 16px;
}

.modal {
  background-color: #1B232A;
  /* box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.25); */
  /* box-shadow: -4px -6px 17px 1px rgba(22, 28, 34, 0.5); */
  box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.25);
  /* border-radius: 10px; */
}

/* .modal.show {
  position: absolute;
  left: 50%;
  top: 180px;
  transform: translateX(-50%);
} */

.modal-loading {
  padding: 20px;
  border-radius: 10px;
}

.modal-header hr {
  margin-bottom: 27px;
}

@media (max-width: 767px) {
  .modal-loading {
    padding: 10px;
    padding: 10px 12px;
    padding-bottom: 25px;
  }

  .modal-header hr {
    margin-bottom: 20px;
  }
}

.modal-loading .btn-danger {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}

@media (max-width: 767px) {
  .modal-loading .btn-danger {
    width: 15px;
    height: 15px;
    right: 12px;
    top: 12px;
  }
}

.modal-loading__progress-bar {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  height: 10px;
  width: 100%;
}

.modal-loading__progress-bar span {
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  height: 100%;
}

.modal-loading__progress-bar--processing span {
  background-color: #4285F4;
  width: 0;
  animation: 2s barTime infinite;
}

.modal-loading__progress-bar--confirmed span {
  background-color: #5ED5A8;
  width: 100%;
  /* animation: 2s barTime infinite; */
  animation: 5s barTime5;
}

.modal-loading__progress-bar--error span {
  background-color: #E84142;
  width: 0;
  animation: 10s barTime5;
}

@keyframes barTime5 {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes barTime {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}



.modal_confirm {
  color: #fff;
}





.modal-loading__title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.modal-loading__title--error {
  color: #E84142;
}

.modal-loading__title--confirmed {
  color: #5ED5A8;
}

@media (max-width: 767px) {
  .modal-loading__title {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.modal-loading__subtitle {
  font-size: 14px;
  font-weight: 400;
  color: #C1C7CD;
}

@media (max-width: 767px) {
  .modal-loading__subtitle {
    font-size: 12px;
  }
}



.modal-loading__title--processing {
  color: #4285F4;
}

/* @media (max-width: 767px) {
  .modal.show {
    max-width: 340px;
    width: 100%;
  }
} */

.modal-small {
  width: 400px;
  height: 300px;
  z-index: 10000;
  box-shadow: 0px 6px 28px -1px rgba(66, 133, 244, 0.3);
  background-color: #1B232A;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modal-small .modal-content {
  box-shadow: none;
  width: auto;
  padding: 0;
}

/* .modal-content {
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  width: 700px;
  padding-bottom: 62px;
  background-color: #1B232A;
  color: #C1C7CD;
  padding: 22px 50px;
  padding-bottom: 62px;
  box-shadow: -4px -6px 17px 1px rgba(22, 28, 34, 0.5);
}

@media (max-width: 767px) {
  .modal-content {
    width: 100%;
    padding: 20px;
    padding-top: 14px;
    padding-bottom: 30px;
  }
} */
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 767px) {
  .modal-body {
    gap: 20px;
  }
}

.modal-body a {
  color: #4285F4;
}

.modal-body__row-direction {
  /* position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.modal-body__row-direction>img {
  width: 19px;
  height: 19px;
  border: 1px solid #3E474F;
  border-radius: 50%;
  padding: 4px;
}

.modal-body__row .br {
  display: none;
}

.modal-body__row .will-date__row label {
  font-size: 10px;
  font-weight: 400;
}

.modal-body__row-direction .your-wills__info-message {
  margin-left: 5px;
}

@media (max-width: 767px) {
  .modal-body__row .br {
    display: block;
    width: 100%;
  }

  .modal-body__row-direction {
    font-size: 14px;
    gap: 2px 5px;
    margin-bottom: 0;
  }


}

.modal-title {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #5ED5A8;
  margin-bottom: 26px;
}

@media (max-width: 767px) {
  .modal-title {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.btn-close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  width: auto;
}

@media (max-width: 767px) {
  .btn-close-modal {
    width: 14px;
    height: 14px;
    top: 14px;
  }

  .modal-await .btn-close-modal {
    right: 14px;
  }
}

.will-date {
  display: flex;
  gap: 20px;
}

.will-date .your-wills__info-message {
  margin-top: 10px;
  margin-left: -10px;
}

.will-date .will-date__row {
  flex-direction: column;
  align-items: flex-start
}

.will-date label {
  text-transform: lowercase;
}

.will-date input {
  font-weight: 700;
}

@media (max-width: 767px) {
  .will-date {
    gap: 15px;
  }

  .will-date .your-wills__info-message {
    margin-top: 7px;
    margin-left: -5px;
  }
}

.will-date__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.will-date__row-input {
  display: flex;
  align-items: center;
}

.will-date__row .input-group {
  height: 40px;
}

@media (max-width: 767px) {
  .will-date__row .input-group {
    height: 30px;
  }
}

.will-date__text {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  display: block;
}

.will-date__text>* {
  display: inline-block;
}

.will-date__text .your-wills__info-message {
  margin-bottom: -2px;
}

.number-of-wills {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 20px;
  /* line-height: 21px; */
  color: #5ED5A8;
}

@media (max-width: 767px) {
  .number-of-wills {
    font-size: 14px;
    gap: 0;
    justify-content: space-between;
    width: 100%;
  }

  .will-date__text {
    margin-bottom: 10px;
  }
}

.will-date__row--checkbox label {
  padding-left: 30px;
  position: relative;
  /* z-index: -1; */
  z-index: 10;
}

.your-wills__settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 50px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .your-wills__settings {
    margin-bottom: 30px;
    margin-top: 13px;
  }
}

.will-date__row--checkbox label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #161C22;
}


.will-date__row--checkbox input:checked+label::before {
  background-image: url('./content/check.svg');
}

.will-date__row label {
  font-size: 14px;
  font-weight: 600;
  color: #C1C7CD;
  /* text-transform: lowercase; */
}

@media (max-width: 767px) {
  .will-date__row label {
    font-size: 12px;
  }
}


.form-check-input {
  position: absolute;
  opacity: 0;
  /* z-index: -1; */
  z-index: 10;
}

.form-check-input[disabled]+label {
  color: rgb(62, 71, 79);
}

.main-text {
  display: flex;
  flex-direction: column;
  /* margin: 70px; */
  justify-content: center;
  align-items: center;
  align-content: center;
  /* margin-top: 140px; */
  margin-top: 108px;
  margin-top: 57px;
  /* padding-bottom: 108px; */
}

.main-text h3,
.main-text p {
  text-shadow: 0px 0px 15px rgb(0, 0, 0);
}

@media (max-width: 767px) {
  .main-text {
    margin-top: 70px;
  }
}



/* .logo-will {
  display: flex;
  width: 144px;
  height: 48px;
  margin-bottom: 15px;
}

.logo-will img {
  display: block;
}

@media (max-width: 767px) {
  .logo-will {
    width: 92px;
    height: 23px;
    order: 0;
    margin-bottom: 0;
  }
} */

.logo-will {
  font-size: 40px;
  font-weight: 800;
  color: #5ED5A8;
  padding-left: 46px;
  position: relative;
  text-decoration: none;
  font-family: "SF UI Text";
}

.logo-will::before {
  content: "";
  position: absolute;
  display: block;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: #5ED5A8;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.btn-header-connect {
  /* display: flex;
  margin-right: 130px;
  border: 1px solid #5ED5A8;
  border-radius: 16px;
  color: #5ED5A8; */
}

@media (max-width: 767px) {
  .logo-will {
    font-size: 22px;
    padding-left: 30px;
  }

  .logo-will::before {
    width: 20px;
    height: 20px;
  }
}



.btn-header-connect button {
  color: #4281F4;
  min-width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: 400;
  border: 1px solid #4285F4;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .btn-header-connect button {
    height: 40px;
    min-width: 150px;
    font-size: 14px;
    border-radius: 10px;
  }

  /* .btn-header-connect {
    position: absolute;
    right: 0;
    top: 0;
  } */
}

.btn-header-connect img {
  display: inline-flex;
  min-width: 17px;
  height: 17px;
  object-fit: cover;
  /* margin-right: 7px; */
  object-fit: contain;
}

.btn-header-connect>div {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px 19px; */
  /* margin-right: 130px; */
  /* border: 1px solid #5ED5A8; */
  /* border-radius: 16px; */
  /* color: #5ED5A8;
  min-width: 190px;
  min-height: 60px; */
  /* width: 100%; */
}

.btn-header__main {
  border: 1px solid #5ED5A8;
  border-radius: 15px;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  z-index: 10;
  /* z-index: -1; */
  cursor: pointer;
  color: #5ED5A8;
}


@media (max-width: 767px) {
  .btn-header__main {
    border-radius: 10px;
    padding: 6px 13px;
  }
}

.btn-header-connect__footer {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.btn-header-connect__footer span {
  display: inline-flex;
}

.btn-header-connect__footer > span {
  display: flex;
  align-items: center;
  gap: 3px;
}

@media (max-width: 767px) {
  .btn-header-connect__footer {
    font-size: 12px;
  }
}

.main-text .btn-header-connect button {
  background-color: #5ED5A8;
  color: #171D22;
  min-width: 350px;
  border: 0;
  box-shadow: 3px -2px 10px rgba(94, 213, 168, 0.4);
}

@media (max-width: 767px) {
  .main-text .btn-header-connect button {
    min-width: 200px;
  }
}

.btn-header__logout,
.btn-change-token {
  display: none;
}

.btn-header__main:hover>.btn-header__logout,
.btn-header__main:hover>.btn-change-token {
  display: block;
}

.btn-header__logout.active {
  display: flex;
}

.btn-header__logout button {
  border: 0;
  border-radius: 0;
  height: auto;
  min-width: auto;
  display: flex;
}

.btn-header__logout button img {
  margin-right: 0;
  min-width: 22px;
  height: auto;
}

.amount-will {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  gap: 4px;
  color: #5ED5A8;
}

.amount-will div:nth-child(2) {
  color: #C1C7CD;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .amount-will {
    align-items: flex-start;
    font-size: 8px;
    margin-left: 0;
    order: 2;
    gap: 2px;
  }

  .amount-will div:nth-child(2) {
    font-size: 8px;
    font-weight: 700;
  }
}

.modal_content {
  z-index: 9998;
}

.modal-open {
  overflow: auto;
  padding: 0;
  /* position: fixed;
  width: 100%;
  height: 100%; */
}

body.modal-open.small-modal {
  overflow: visible !important;
}

.modal_title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5ED5A8;
  /* margin-top: 22px; */
  margin-bottom: 29px;
  /* font-size: 18px; */
  font-size: 22px;
}

.modal_will-tokens {
  display: flex;
  color: #C1C7CD;
  margin-top: 28px;
  margin-bottom: 36px;
  align-items: center;
}

.modal-choose.show {
  position: fixed;
  /* min-width: 350px; */
  /* width: 100%; */
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 24px;
  padding-left: 47px;
  padding-right: 47px;
  top: 20px;
  right: 80px;
  left: auto;
  transform: none;
  z-index: 100;
}

.modal-choose__title {
  font-size: 18px;
  font-weight: 400;
  color: #4285F4;
  text-align: center;
  margin-bottom: 20px;
}

.modal-choose .modal-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

/* 
.modal-content .modal-footer {
  position: relative;
} */

@media (max-width: 767px) {
  .modal_title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .modal-choose__title {
    font-size: 14px;
  }

  .modal-choose.show {
    padding: 25px 10px;
    top: 15px;
    left: 0;
    right: 0;
    max-width: 340px;
    margin: auto;
  }
}

.modal-choose .modal-footer img:hover,
.modal-choose .modal-footer img:active {
  border-color: #5ED5A8;
}

@media (max-width: 767px) {
  .modal-choose {
    max-width: 340px;
    padding: 15px 22px 25px 22px;
  }

  .modal-footer {
    font-size: 14px;

  }

  .modal-choose .modal-footer img {
    width: 67px;
    height: 67px;
  }
}

.form-select {
  /* margin-left: 14px;
  margin-right: 15px; */
  color: #4285F4;
  background: #161C22;
  /* width: 105px; */
  /* padding: 7.5px; */
  padding: 0 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  border: none;
  border-radius: 10px;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
}

.select-pic {
  width: 15px;
  height: 15px;
}

.form-select__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 105px;
  width: 100%;
  flex: 1 0 auto;
  height: 40px;
}

.form-select__arrow {
  position: absolute;
  right: 0;
  background-color: #1D252C;
  border-radius: 0 10px 10px 0;
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.form-select:after {
  content: "▼";
  padding: 0 8px;
  font-size: 12px;
  position: absolute;
  z-index: 9999;
  text-align: center;
  width: 10%;
  pointer-events: none;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .form-select {
    font-size: 14px;
  }

  .form-select__wrapper {
    width: 80px;
    height: 30px;
  }

  .form-select__arrow {
    width: 29px;
  }
}

.input-max {
  width: 80px;
  margin-left: 20px;
  padding-top: 12px;
  padding-bottom: 18px;
  background: #161C22;
  border-radius: 10px;
  color: #4285F4;
  border: none;
}

.input-max-button {
  background: #1D252C;
  box-shadow: 8px 14px 8px -10px rgb(22 28 34 / 55%);
  padding: 8px 6px 9px 7px;
  border-radius: 10px;
  color: #5ED5A8;
  border: none;
}

.input-max:after {
  content: " ";
  background: #3E474F;
  padding: 0 8px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

select {
  padding-right: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.modal_wallet {
  margin-bottom: 36px;
  color: #C1C7CD;
}

.modal_wallet_link {
  color: #4285F4;
}

.title_trusted-wallet {
  color: #C1C7CD;
  font-size: 18px;
  margin-bottom: 10px;
}

.title_trusted-wallet:last-child {
  margin-bottom: 0;
}

.input_trusted-wallet {
  width: 600px;
  background: #161C22;
  border-radius: 10px;
  padding: 15px;
  border: none;
  margin-bottom: 43px;
  margin-top: 10px;
  color: #C1C7CD;
}

.input-amount-max {
  width: 84px;
  background: #161C22;
  color: #4285F4;
  border-radius: 10px;
  border: none;
}

.input-max {
  border: none;
  background-color: #161c22;
  border-radius: 10px;
  display: flex;
  padding: 3px 10px 3px 13px;
  box-shadow: none;
  outline: 0;
  outline-offset: 0;
}

.input-amount-max:focus {
  outline: 0;
  outline-offset: 0;
}

.modal_time-will {
  display: flex;
}

.modal_title-time-will {
  color: #C1C7CD;
  font-size: 18px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .modal_title-time-will {
    font-size: 14px;
    /* margin-top: 10px; */
    margin-bottom: 10px;
  }
}

.modal_time-years {
  display: flex;
  flex-direction: column;
  margin-right: 23px;
}

.modal_time-months {
  display: flex;
  flex-direction: column;
  margin-right: 23px;
}

.modal_time-days {
  display: flex;
  flex-direction: column;
  margin-right: 23px;
}

.img_question {
  margin-left: 7px;
}

.input-group-time {
  padding: 8px 40px 11px 10px;
  background: #161C22;
  border-radius: 10px;
  color: #4285F4;
  border: none;
  box-shadow: -4px -6px 17px 1px rgba(22, 28, 34, 0.5);
  max-width: 80px;
}

.input-group-time-name {
  width: 18px;
  font-size: 10px;
  color: #C1C7CD;
  padding: 10px;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-amount-maxbutton {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  background: #1D252C;
  color: #5ED5A8;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
}

.checkbox_unlimited {
  margin-right: 17px;
  margin-left: 5px;
}

.modal_checkbox {
  color: #C1C7CD;
}

.text-btn-connect {
  color: #4285F4;
}

#checkbox {
  background: #161C22;
  border-radius: 5px;
}

.button_make-new-will {
  /* text-align: center;
  max-width: 550px;
  background: #3E474F;
  border-radius: 15px;
  border: none;
  font-size: 18px;
  margin-left: 49px;
  line-height: 21px;
  align-items: center;
  justify-content: center;
  color: #171D22; */
}

.button_approve {
  display: flex;
  text-align: center;
  margin-bottom: 15px;
  max-width: 550px;
  background: #3E474F;
  border-radius: 15px;
  padding: 15px 238px;
  border: none;
  font-size: 18px;
  margin-left: 49px;
  line-height: 21px;
  align-items: center;
  justify-content: center;
  color: #171D22;
}

.button_number-span {
  /* display: flex; */
  /* width: 124px; */
  text-align: center;
}

.button_ {
  display: flex;
  text-align: center;
  margin-bottom: 15px;
  max-width: 550px;
  background: #3E474F;
  border-radius: 15px;
  padding: 15px 238px;
  border: none;
  font-size: 18px;
  margin-left: 49px;
  line-height: 21px;
  align-items: center;
  justify-content: center;
  color: #171D22;
}

.bnt_close {
  background-color: #1B232A;
  color: #3E474F;
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  box-shadow: none;
}

.title_trusted-wallet {
  gap: 25px;
}

.btn_reset-timers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  font-family: 'SF UI Text';
  padding: 15px 34px 19px 34px;
  background: #5ED5A8;
  border: 1px solid #5ED5A8;
  /* box-shadow: 0px 4px 4px rgba(94, 213, 168, 0.25); */
  border-radius: 15px;
  color: #1B1711;
  font-weight: 400;
  font-size: 20px;
  font-family: 'SF UI Text';
  margin: auto;
  margin-top: 14px;
}

@media (max-width: 767px) {
  .btn_reset-timers {
    margin-top: 0;
  }

  .bnt_close {
    width: 14px;
    height: 14px;
    top: 15px;
    right: 18px;
  }
}

.your_inheritances {
  /* text-align: center; */
  /* margin-top: 33px; */
  margin-top: 80px;

}

@media (max-width: 767px) {
  .your_inheritances {
    margin-top: 50px;
  }
}

.wills-description-block {
  position: relative;
  max-width: 700px;
  width: 100%;
  padding: 30px 50px;
  background: #1B232A;
  box-shadow: 2px 0px 19px 3px #161c22;
  color: #C1C7CD;
  margin-bottom: 19px;
}

.wills-description-block .your-wills__info-message {
  right: 10px;
  top: 10px;
  position: absolute;
}

.wills-description-block__header,
.page-data__block-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 26px 5px;

}

.your-wills {
  /* border-top: 1px solid #3E474F; */
  border-bottom: 1px solid #3E474F;
}

.your-wills:first-child {
  border-top: 1px solid #3E474F;
}

.your_inheritances_ul li {
  border-bottom: 1px solid #3E474F;
}

.your_inheritances_ul li:first-child {
  border-top: 1px solid #3E474F;
}

.wills-description-block__col a {
  /* font-weight: 600; */
}

.wills-description-block__id {
  position: absolute;
  top: -9px;
  left: 10px;
  padding: 0 5px;
  font-size: 8px;
  font-weight: 400;
  background-color: #1B232A;
}

.wills-description-block__symbol {
  color: #4285F4;
}

@media (max-width: 767px) {
  .wills-description-block {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 23px;
    padding-left: 20px;
    /* text-align: center; */
  }

  .wills-description-block__header {
    flex-direction: column;
  }

  .wills-description-block__header,
  .page-data__block-container {
    padding: 20px 5px;
  }
}

.your-inheritances_color-text {
  color: #4285F4;
}

.wills_list-my-wills {
  /* width: 700px; */
  margin-top: 50px;
  /* padding: 20px 50px;
  background: #1B232A;
  box-shadow: 2px 0px 19px 3px #161c22;
  color: #C1C7CD;
  margin-top: 62px;
  margin-bottom: 84 px; */
}

.wills_list-my-wills h4 {
  font-weight: 400;
}

.your_inheritances h4 {
  font-weight: 400;
}

@media (max-width: 767px) {
  .wills_list-my-wills {
    margin-top: 20px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 138px;
}

.your-wills {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* padding-bottom: 30px; */
  line-height: 17px;
  letter-spacing: 0.0264em;
  color: #C1C7CD;
}

.your-wills__current-token {
  font-size: 18px;
  font-weight: 400;
  background-color: #161C22;
  color: #4285F4;
  width: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .your-wills__current-token {
    height: 30px;
    font-size: 14px;
  }
}

.your-wills__checkbox {
  cursor: pointer;
}

.your-wills__checkbox label {
  position: relative;
}

@media (max-width: 767px) {
  .your-wills__checkbox label {
    font-size: 14px;
  }
}

.your-wills__checkbox input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.your-wills__checkbox label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #5ED5A8;
  transform: translateY(-50%);
}

.your-wills__checkbox input:checked+label::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  left: 6px;
  top: 50%;
  background-color: #5ED5A8;
  transform: translateY(-50%);
  border-radius: 50%;
}

.your-wills__checkbox label {
  padding-left: 30px;
}

.your-wills__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: space-between; */
  grid-gap: 15px;
}

.your-wills__header .your-wills__info-message {
  margin-left: -5px;
}

.your-wills__check-token .your-wills__info-message {
  margin-left: -5px;
}

@media (max-width: 767px) {
  .your-wills__header {
    gap: 10px;
    justify-content: flex-start;
    /* font-size: 14px; */
  }

  .your-wills__header .your-wills__info-message {
    margin-left: 0;
  }
}

.your-wills__max {
  position: relative;
  width: 150px;
  display: flex;
  box-shadow: 8px 14px 8px -10px rgba(22, 28, 34, 0.55);
  height: 40px;
  border-radius: 10px;
  overflow: hidden;
  width: 150px;
}

.your-wills__max input {
  border-radius: 10px 0 0 10px;
  width: 100%;
}

.your-wills__max input::placeholder {
  font-size: 10px;
}

@media (max-width: 767px) {
  .your-wills__max {
    height: 30px;
    width: 130px;
  }

  .your-wills__max input {
    /* width: 95px; */
  }
}

.your-wills__max button {
  position: absolute;
  width: 35px;
  right: 0;
  top: 0;
  width: 35px;
  background-color: #1D252C;
  color: #5ED5A8;
  font-size: 10px;
  font-weight: 400;
  border: 0;
  height: 100%;
}

.your_inheritances_ul-btn {
  display: flex;
}

.your-inheritances_info {
  display: flex;
  margin-top: 14px;
}

.your_inheritances_ul {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}

.your_inheritances-h3,
.wills_list_h3 {
  /* width: 600px; */
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.0264em;
  color: #5ED5A8;
  /* padding-bottom: 17px; */
  margin: 0 auto;
  margin: 0;
  width: auto;
}

@media (max-width: 767px) {}

ul.your-wills__footer {
  list-style: number;
  list-style-type: number;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* list-style-position: inside; */
}

@media (max-width: 767px) {
  .your-wills__footer {
    gap: 20px;
  }
}

.your-wills__footer li {
  padding-left: 20px;
  /* position: relative; */
  margin-left: 20px;
  /* z-index: -1; */
}

.your-wills__footer button {
  /* width: 100%; */
  width: calc(100% - 30px);
  height: 50px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 15px;
  border: 0;
  box-shadow: none;
  color: #171D22;
  /* margin-left: 30px; */
}

.your-wills__footer .your-wills__info-message {
  display: inline-block;
}

@media (max-width: 767px) {
  .your-wills__footer button {
    height: 40px;
    font-size: 14px;
  }

  .your-wills__footer li {
    padding-left: 20px;
  }
}

/* .modal-footer > div {
  display: flex;
  flex-direction: column;
} */

/* .wills_list_h3 {
  width: 600px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.0264em;
  color: #5ED5A8;
  padding: 17px;
} */

.btn_btn-success {
  display: flex;
  min-width: 120px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  border: 1px solid #5ED5A8;
  height: 40px;
  color: #5ED5A8;
  background: #1B232A;
  box-shadow: 2px 0px 19px 3px #161C22;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  gap: 5px;
}

@media (max-width: 767px) {
  .btn_btn-success {
    font-size: 12px;
    min-width: 120px;
    height: 40px;
  }
}

.btn_btn-danger {
  display: flex;
  width: 120px;
  max-height: 40px;
  font-style: normal;
  font-weight: 400;
  margin-top: 81px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #5ED5A8;
  height: 45px;
  color: #5ED5A8;
  background: #1B232A;
  box-shadow: 2px 0px 19px 3px #161C22;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.btn_receive {
  margin-left: 100px;

}

.block_your-wills-h3 {
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.0264em;
  color: #5ED5A8;
}

.your-wills-text {
  word-break: break-word;
  padding-bottom: 20px;
}

.your_inheritances_ul-text {
  color: #C1C7CD;
  /* max-width: 322px; */
  word-break: break-word;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0264em;
  /* padding-bottom: 27px; */
}

/* .your_inheritances_ul-text a {
  font-weight: 600;
} */

.your_inheritances_ul-text__head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.input-group-year {
  width: 105px;
  height: 40px;
  background: #161C22;
  border-radius: 10px;
  color: #4285F4;
}

.input-group {
  width: 80px;
  height: 100%;
  background-color: #161C22;
  border-radius: 10px;
  border: 0;
  padding: 0 10px;
  color: #4285F4;
}

@media (max-width: 767px) {
  .input-group {
    width: 50px;
  }

  .your_inheritances_ul-text__head {
    padding-bottom: 20px;
    margin-bottom: 0;
  }
}

.input-group::-webkit-outer-spin-button,
.input-group::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group[type="number"] {
  -moz-appearance: textfield;
}

.input-group:focus-visible {
  outline: 0;
}


/* .input-group[type="number"]::-webkit-input-placeholder,
.input-group[type="number"]:-moz-placeholder,
.input-group[type="number"]::-moz-placeholder,
.input-group[type="number"]:-ms-input-placeholder,
.input-group[type="number"]::-ms-input-placeholder, */
.input-group[type="number"]::placeholder {
  color: #3E474F;
}

.your-wills_btns {
  display: flex;
  flex-direction: column;
}

.btn_green {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  gap: 8px;
  margin: 23 px 48px 10px 138px;
  border: 1px solid #5ED5A8;
  color: #5ED5A8;
  background: #1B232A;
  box-shadow: 2px 0px 19px 3px #161C22;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

/* .btn_green_revoke {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  padding: 13px 29px;
  width: 120px;
  gap: 8px;
  padding: 13px 29px;
  max-height: 40px;
  border: 1px solid #5ED5A8;
  background-color: #161c22;
  color: #5ED5A8;
  background: #1B232A;
  box-shadow: 2px 0px 19px 3px #161C22;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 13px 29px;
  max-height: 40px;
} */

.page-data {
  max-width: 1172px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-data a {
  color: #4285F4;
}

.page-data__block-container {
  gap: 40px;
}

@media (max-width: 767px) {
  .page-data__block-container {
    flex-direction: column;
    gap: 20px;
  }
}

.btn_data-js {
  margin-top: 50px;
}

.btn_data-js>div {
  display: flex;
}

@media (max-width: 767px) {
  .btn_data-js {
    margin-top: 50px;
  }
}

/* .modal_checkbox-add-nft {
  margin-top: 40px;
  margin-right: 5px;
  margin-bottom: 15px;
}
.modal_checkbox-automatic-token-delivery {
  margin-bottom: 14px;
  margin-right: 5px;
}
.modal_checkbox-notication {
  margin-bottom: 48px;
  margin-right: 5px;
  margin-bottom: 14px;
} */

.wills-list_ul-btn {
  display: flex;
  /* border-top: 1px solid #3E474F; */
}

#wills-list_ul-btn {
  display: flex;
  flex-direction: column;
  /* gap: 40px; */
  gap: 0;
}

.modal-confirm.show {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1B232A;
  color: #4285F4;
  /* padding: 50px 70px 48px 77px; */
  max-width: 400px;
  width: 100%;
  padding: 50px 0 !important;
  box-shadow: 0px 6px 28px -1px rgba(66, 133, 244, 0.3);
  border-radius: 10px;
  z-index: 9999;
}

@media (max-width: 767px) {
  .modal-confirm {
    max-width: 340px;
  }

  .modal-confirm.show {
    max-width: 340px;
  }
}

.modal-confirm .modal-content {
  box-shadow: none;
  color: #4282F4;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
}

/* 
.modal-confirm .modal-content img {
  animation: rotateInfinity 1s infinite linear;
}

@keyframes rotateInfinity {
  from {
    transform: rotate(0%);
  }

  to {
    transform: rotate(180deg);
  }
} */

.modal-confirm_h2 {
  width: 173px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
}

@media (max-width: 767px) {
  .modal-confirm_h2 {
    font-size: 14px;
  }
}

.modal-confirm_text {
  width: 247px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
}

@media (max-width: 767px) {
  .modal-confirm_text {
    font-size: 14px;
  }
}

.modal-await {
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate (-50%, -50%);
  background: #1B232A;
  box-shadow: 0px 6px 28px -1px rgba(94, 213, 168, 0.25);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

@media (max-width: 767px) {
  .modal-await {
    max-width: 340px;
  }
}

.modal-await .modal-dialog {
  align-self: center;
}

/* .spinner {
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

.modal-await .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 45px;
}

.modal-await_text {
  /* position: absolute; */
  /* width: 265px; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #5ED5A8;
  /* text-decoration: underline; */
}

.modal-await_text__second {
  font-size: 12px;
  text-align: center;
  margin-top: 40px;
}

.modal-await_text__second  span {
  text-decoration: underline;
}

.btn_reset-timers-h2 {
  width: 142px;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

@media (max-width: 767px) {
  .btn_reset-timers-h2 {
    font-size: 18px;
  }
}

.btn_reset-timers-h3 {
  margin-top: 5px;
  max-width: 332px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

@media (max-width: 767px) {
  .btn_reset-timers-h3 {
    font-size: 12px;
    margin-top: 10px;
  }

  .btn_reset-timers {
    padding: 17px 28px;
    max-width: 100%;
    width: 100%;
  }
}

.your-wills_remain {
  color: #4285F4;
}

.your-wills_date {
  color: #4285F4;
}

.your-wills_text {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: space-evenly;
  align-items: stretch;
  word-break: break-word;
  width: 100%;
}

.your-wills_text-info a {
  margin-bottom: 20px;
  display: block;
}

.your-wills__btns {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.your-wills_text-info__footer {
  font-size: 12px;
}

.your-wills_text-info__footer>* {
  display: inline-block;
}

@media (max-width: 767px) {
  .your-wills__btns {
    flex-direction: row;
    justify-content: center;
  }
}

.your-wills__wallet {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.your-wills__wallet input {
  width: 100%;
  background-color: #161C22;
  border-radius: 10px;
  border: 0;
  padding: 0 10px;
  color: #C1C7CD;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
}

.your-wills__wallet input::placeholder {
  font-size: 10px;
  color: #3E474F;
}

@media (max-width: 767px) {
  .your-wills__wallet {
    margin-top: 0;
    font-size: 14px;
  }

  .your-wills__wallet input {
    height: 30px;
  }
}

.your-wills__wallet input+p {
  transition: all 0.3s ease;
  font-size: 10px;
  font-weight: 400;
  color: #DD4B4B;
  margin-top: -5px;
}

.your-wills__wallet input+p.active {
  transition: all 0.3s ease;
}


.your-wills__wallet input:focus-visible {
  outline: 0;
}

.your-wills__check-token {
  display: flex;
  /* gap: 30px; */
  gap: 15px;
  align-items: center;
  flex: 1 0 auto;
}

.your-wills__links {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.your-wills__links span {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  color: #C1C7CD;
}

.your-wills__links:last-child {
  margin-bottom: 0;
}

.your-wills__links a {
  font-size: 14px;
  font-weight: 400;
  color: #C1C7CD;
  display: flex;
  align-items: center;
  gap: 10px;
}

.your-wills__links a img {
  width: 22px;
  height: 22px;
}

.your-wills__links img {
  width: 22px;
  height: 22px;
}

.your-wills__notifications textarea {
  width: 100%;
  min-height: 140px;
  resize: none;
  background-color: #161C22;
  border-radius: 10px;
  padding: 10px 15px;
  border: 0;
  color: #C1C7CD;
  margin-bottom: 30px;
}

.your-wills__notifications textarea::placeholder {
  color: #3C4146;
}

.your-wills__notifications textarea:focus-visible {
  border: 0;
  outline: 0;
}

.your-wills__notifications>span {
  font-size: 10px;
  font-weight: 400;
  color: #C1C7CD;
  line-height: 12px;
  margin-top: 10px;
  margin-bottom: 15px;
  display: block;
}

@media (max-width: 767px) {
  .your-wills__check-token {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .your-wills__notifications>span {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .your-wills__notifications textarea {
    margin-bottom: 10px;
  }
}


.your_inheritances_li {
  margin-bottom: 20px;
  margin-top: 20px;
}

.modal-wrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9;
  background-image: url('./background.jpg');
}

.modal-loading.modal.show {
  position: fixed;
  width: 500px;
  min-height: 100px;
  top: 50px;
  right: 50px;
  left: auto;
  transform: translate(0);
  z-index: 100;
}

.modal-loading__link {
  position: absolute;
  right: 60px;
  top: 23px;
}

@media (max-width: 767px) {
  .modal-loading.modal.show {
    top: 15px;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translateX(-50%);
    width: 340px;
    min-height: 80px;
  }

  .modal-loading__link {
    right: 39px;
    top: 12px;
    width: 12px;
    height: 12px;
  }
}

.button_number_one {
  display: flex;
  width: 14px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  margin-top: 40px;
  align-items: baseline
}

.button_number_two {
  display: flex;
  width: 14px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  margin-top: 15px;
  align-items: baseline
}

.modal_input-max {
  background: #161C22;
  width: 150px;
  padding: 8px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-radius: 10px;
  max-height: 40px;
  margin-left: 29px;
}

.checkbox_unlited {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* .checkbox_unlited + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
} */
.checkbox_unlited+label::before {
  content: url(./content/button-unlited.svg);
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #5ED5A8;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  margin-left: 28px;
}

.custom-checkbox:checked+label::before {
  border-color: #5ED5A8;
  background-color: #5ED5A8;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.modal_checkbox-contaner {
  display: none;
}

.modal_checkbox-label {
  padding-left: 20px;
}

.modal_checkbox-label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #161C22;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  vertical-align: middle;
}

.modal_checkbox-contaner+.modal_checkbox-label::before {
  background-color: #5ED5A8;
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.modal-choose-wallet.show {
  position: fixed;
  top: 20px;
  right: 80px;
  left: auto;
  transform: none;
  max-width: 350px;
  width: 100%;
  padding: 22px;
  padding-bottom: 29px;
  background-color: #1D252C;
  border-radius: 10px;
  color: #4285F4;
}

@media (max-width: 767px) {
  .modal-choose-wallet.show {
    top: 50px;
    left: 0;
    right: 0;
    max-width: 340px;
    margin: auto;
  }
}

.modal-choose-wallet .modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-choose-wallet .modal-footer {
  flex-direction: column;
}

.modal-choose-wallet .modal-footer .title_trusted-wallet {
  display: flex;
  flex-direction: column;
  gap: 0;
  font-size: 14px;
  font-weight: 700;
}

.modal-choose-wallet .modal-footer .title_trusted-wallet:last-child {
  font-weight: 400;
}

.modal-choose-wallet .modal-footer .title_trusted-wallet a {
  color: #4285F4;
}

.modal-choose-wallet .modal-footer>* {
  color: #4285F4;
}

.modal-choose-wallet h1 {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #4285F4;
}

.modal-choose-wallet .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
}

.modal-choose-wallet .modal-body button {
  width: 90px;
  height: 90px;
  padding: 15px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #3E474F;
}

.modal-choose-wallet .modal-body button:hover {
  box-shadow: 0px 1px 4px 1px rgba(94, 213, 168, 0.25);
  border-color: #5ED5A8;
}

@media (max-width: 767px) {
  .modal-choose-wallet {
    max-width: 340px;
  }

  .modal-choose-wallet h1 {
    font-size: 14px;
  }

  .modal-choose-wallet .modal-body button {
    width: 67px;
    height: 67px;
  }

  .modal-choose-wallet .modal-body {
    margin-bottom: 10px;
  }
}

/* .fake {
  display: block;
  content : "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  background-color: #161C22;
}
.fake::before {
  content : "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: .2s;

}
.modal_checkbox-contaner:checked + .fake::before {
  opacity: 1;
  content : "kkk";
  background-color: #61dafb;
}  */

.btn-header-connect .btn-change-token {
  font-size: 14px;
  color: #4281F4;
  min-width: auto;
  /* width: 24px; */
  height: auto;
  /* height: 24px; */
  /* font-size: 18px; */
  font-weight: 400;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  /* margin-left: 5px; */
  position: relative;
  z-index: 20;
  /* border-bottom: 1px solid #4281F4; */
}

.btn-header-connect .btn-change-token img {
  width: 24px;
  height: 24px;
  display: block;
}

@media (max-width: 767px) {
  .btn-header-connect .btn-change-token {
    font-size: 12px;
  }

  .btn-header-connect {
    order: 1;
  }

  .btn-header__wallet {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.ml-loader {
  position: relative;
  width: 70px;
  height: 70px;
}

.ml-loader div {
  -webkit-transform-origin: 32px 32px;
  -ms-transform-origin: 32px 32px;
  transform-origin: 32px 32px;
  -webkit-animation: 1.2s opaque ease-in-out infinite both;
  animation: 1.2s opaque ease-in-out infinite both;
}

.ml-loader div::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 30px;
  width: 7px;
  height: 15px;
  border-radius: 10px;
  background-color: #03A9F4;
}

.ml-loader div:nth-child(1) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.ml-loader div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.ml-loader div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.ml-loader div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.ml-loader div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.ml-loader div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.ml-loader div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.ml-loader div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.ml-loader div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.ml-loader div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.ml-loader div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.ml-loader div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.ml-loader div:nth-child(13) {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@-webkit-keyframes opaque {

  0%,
  40%,
  100% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }
}

@keyframes opaque {

  0%,
  40%,
  100% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }
}

.chosen-network {
  /* background-color: yellow; */
}

.modal-choose .modal-footer img {
  border: 1px solid #3E474F;
  border-radius: 10px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  padding: 15px;
  width: 55px;
  height: 55px;
  flex: 1 0 auto;
}

/* цвет сети */
:root {
  --color-polygon: #8247E5;
  --color-ethereum: #7986CB;
  --color-binance: #F0B90B;
  --color-avalanche: #E84142;
  --color-optimism: #FE0000;
  --color-arbitrum: #96BEDC;
  --color-polygon-active: #8247E590;
  --color-ethereum-active: #7986CB90;
  --color-binance-active: #F0B90B90;
  --color-avalanche-active: #E8414290;
  --color-optimism-active: #FE000090;
  --color-arbitrum-active: #96BEDC90;
}

.modal-choose .modal-footer img[alt="Polygon"] {
  border-color: var(--color-polygon);
}

.modal-choose .modal-footer img[alt="Ethereum"] {
  border-color: var(--color-ethereum);
}

.modal-choose .modal-footer img[alt="Binance"] {
  border-color: var(--color-binance);
}

.modal-choose .modal-footer img[alt="Avalanche"] {
  border-color: var(--color-avalanche);
}

.modal-choose .modal-footer img[alt="Optimism"] {
  border-color: var(--color-optimism);
}

.modal-choose .modal-footer img[alt="Arbitrum"] {
  border-color: var(--color-arbitrum);
}

.modal-choose .modal-footer img[alt="Polygon"].chosen-network,
.modal-choose .modal-footer img[alt="Polygon"]:hover {
  box-shadow: 0 0 5px 3px var(--color-polygon-active);
}

.modal-choose .modal-footer img[alt="Ethereum"].chosen-network,
.modal-choose .modal-footer img[alt="Ethereum"]:hover {
  box-shadow: 0 0 5px 3px var(--color-ethereum-active);
}

.modal-choose .modal-footer img[alt="Binance"].chosen-network,
.modal-choose .modal-footer img[alt="Binance"]:hover {
  box-shadow: 0 0 5px 3px var(--color-binance-active);
}

.modal-choose .modal-footer img[alt="Avalanche"].chosen-network,
.modal-choose .modal-footer img[alt="Avalanche"]:hover {
  box-shadow: 0 0 5px 3px var(--color-avalanche-active);
}

.modal-choose .modal-footer img[alt="Optimism"].chosen-network,
.modal-choose .modal-footer img[alt="Optimism"]:hover {
  box-shadow: 0 0 5px 3px var(--color-optimism-active);
}

.modal-choose .modal-footer img[alt="Arbitrum"].chosen-network,
.modal-choose .modal-footer img[alt="Arbitrum"]:hover {
  box-shadow: 0 0 5px 3px var(--color-arbitrum-active);
}

@media (max-width: 767px) {
  .modal-choose .modal-footer img {
    max-width: 35px;
    height: 35px;
    padding: 7px;
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  background-color: #020A1350;
}

.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 65px;
}

footer a {
  color: #C1C7CD;
  font-size: 15px;
  font-weight: 400;

}

.footer__social a {
  width: 25px;
  /* height: 25px; */
}

.footer__social img {
  display: block;
}

.footer__social {
  display: flex;
  align-items: center;
  gap: 25px;
}

.footer__social-document {
  margin-right: 25px;
}

@media (max-width: 767px) {
  footer a {
    font-size: 14px;
  }
  .footer__social {
    gap: 15px;
  }
  .footer__social a {
    width: 24px;
    /* height: 24px; */
  }
  .footer__wrapper {
    min-height: 50px;
  }
  .footer__social-document {
    margin-right: 15px;
  }
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 0%);
  border-color: hsl(0, 0%, 0%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.your-wills__info-message {
  margin-left: 10px;
  min-width: 15px;
  height: 15px;
  /* position: relative; */
  /* z-index: 10; */
  /* z-index: 1; */
}

.your-wills__info-message img {
  display: block;
}

.your-wills__wallet-row {
  display: flex;
  align-items: center;
}


@media (max-width: 767px) {
  .your-wills__info-message {
    margin-left: 10px;
  }
}

.your-wills__count {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.your-wills__count>* {
  /* display: inline-block */
}

.your-wills__info-message:hover::after {
  /* content: attr(data-title);
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -120px;
  background: #1B232A;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 0px 19px 3px #161C22;
  font-size: 14px;
  font-weight: 600; */
}

.your-wills__info-message:hover::after{
  content: attr(data-title);
  position: absolute;
  white-space: pre-wrap;
  z-index: 1;
  /* bottom: 125%; */
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -120px; */
  background: #1B232A;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 0px 19px 3px #161C22;
  font-size: 14px;
  font-weight: 600;
  /* top: 0; */
  /* bottom: auto; */
  z-index: 10000000000;
  /* width: 100%; */
  /* margin-left: 0; */
  box-sizing: border-box;
}

.your_inheritances_ul-text__head .your-wills__info-message:hover::after {
  right: 0;
  left: auto;
  transform: translateX(0);
  /* width: 100%; */
  min-width: 300px;
}

@media (max-width: 767px) {
  .your-wills__info-message:hover::after {
    font-size: 12px;
    max-width: 300px;
    width: 100%;
  }

  .your_inheritances_ul-text__head .your-wills__info-message:hover::after {
    /* right: 0;
    left: auto;
    transform: translateX(0);
    min-width: 100px; */
    width: 100%;
  }
}

#space {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
  z-index: -2;
}

.css-1ruxqpb-menu{
  min-width: 100px;
}

.footer__copy {
  text-decoration: none;
}
